// Solution was lifted from these docs: https://www.gatsbyjs.com/docs/using-client-side-only-packages/#workaround-4-load-client-side-dependent-components-with-loadable-components
import React from "react"
import Loadable from "@loadable/component"

const LoadableBuyButton = Loadable(() => import("./ShopifyButton"))

const ShopifyComponent = () => {
  return <LoadableBuyButton></LoadableBuyButton>
}

export default ShopifyComponent
