/** @format */

import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import ContentSection from "../components/ContentSection"
import ImageInset from "../components/ImageInset"
import ShopifyComponent from "../components/ShopifyComponent"
import HyperLinkText from "../components/HyperLinkText"

// eslint-disable-next-line
export const ShopPageTemplate = ({ title }) => {
  return (
    <div>
      <section className="section">
        <div className="container">
          <div className="columns is-multiline is-centered">
            <div className="column is-8 is-6-desktop">
              <h2 className="mb-5 is-size-1 is-size-3-mobile has-text-weight-bold">
                {title}
              </h2>
              <p className="subtitle has-text-grey mb-5">
                Grab a flag in our online store. Or hit the town and support a
                local vendor!​
              </p>
            </div>
          </div>
        </div>
      </section>
      <ContentSection backgroundColorClass={"has-background-light"}>
        <div className="columns is-multiline">
          <div className="column is-12 is-justify-content-right">
            <h2 className="mb-5 is-size-1 is-size-3-mobile has-text-weight-bold">
              Shop online
            </h2>
            <p className="subtitle has-text-grey mb-5">
              Choose from our online selections here.
            </p>
            <ShopifyComponent />
          </div>
        </div>
      </ContentSection>
      <ContentSection backgroundColorClass={""}>
        <div className="columns is-multiline">
          <div className="column is-12 is-justify-content-right">
            <h2 className="mb-5 is-size-1 is-size-3-mobile has-text-weight-bold">
              Shop local
            </h2>
            <p className="subtitle has-text-grey mb-5">
              Support a local Raleigh business.
            </p>
            <VendorCard
              vendorName={"Capitol Flag & Flagpole"}
              vendorAddress={"623 W Lane St"}
              vendorAddressURL={
                "https://goo.gl/maps/pYUMGFfxTKhPYHeQA?coh=178573&entry=tt"
              }
              vendorOfferings={[
                "3' x 5' printed flags",
                "Flagpoles & flagpole installation​",
              ]}
              storeLink={"https://www.capitolflagandflagpole.com/"}
              storeLinkText={"Visit store website"}
              vendorImgSrc={"/img/nrf_shop_page_capitol_flag_and_flagpole.jpg"}
              vendorImgAlt={"nrf_shop_page_capitol_flag_and_flagpole"}
            />
            <VendorCard
              vendorName={"DECO Raleigh"}
              vendorAddress={"207 S Salisbury Street"}
              vendorAddressURL={"https://maps.app.goo.gl/xFY2GRhHX9fDYEfe8"}
              vendorOfferings={["3' x 5' printed flags", "Garden flags​"]}
              storeLink={"https://www.decoraleigh.com/"}
              storeLinkText={"Visit store website"}
              vendorImgSrc={"/img/nrf_shop_page_deco.png"}
              vendorImgAlt={"nrf_shop_page_deco"}
            />
            <VendorCard
              vendorName={"Flag Designs"}
              vendorAddress={
                "Raleigh Market, Education Building, 1025 Blue Ridge Rd"
              }
              vendorAddressURL={
                "https://goo.gl/maps/HZAmSN41Wivoiey28?coh=178573&entry=tt"
              }
              vendorOfferings={["Garden flags​"]}
              storeLink={"https://flagdesigns.com/"}
              storeLinkText={"Visit store website"}
              vendorImgSrc={"/img/nrf_shop_page_raleigh_market.webp"}
              vendorImgAlt={"nrf_shop_page_raleigh_market"}
            />
            <VendorCard
              vendorName={"The Oak City Collection"}
              vendorAddress={"​105 S Third Street, Smithfield"}
              vendorAddressURL={"https://maps.app.goo.gl/z6i2FgsmV5BM5TWR9"}
              vendorOfferings={["Apparel & custom screened items​​"]}
              storeLink={"https://oakcitycollection.com/"}
              storeLinkText={"Visit store website"}
              vendorImgSrc={"/img/nrf_shop_page_oak_city_collection.webp"}
              vendorImgAlt={"nrf_shop_page_oak_city_collection"}
            />
          </div>
        </div>
      </ContentSection>
      <ContentSection backgroundColorClass={"has-background-light"}>
        <div className="columns is-multiline is-centered">
          <div className="column is-8 is-6-desktop">
            <h2 className="mb-4 is-size-3 is-size-3-mobile has-text-weight-bold">
              Looking for new Raleigh flag-themed swag?​
            </h2>
          </div>

          <ImageInset
            images={[
              "/img/newflag_hat.png",
              "/img/newflag_mug.png",
              "/img/newflag_shirt.png",
            ]}
          />
          <p className="subtitle has-text-grey mb-5">
            We aren't merchandisers, but we've partnered with Zazzle to create
            some flag-themed products. Check them out in the New Raleigh Flag
            store at Zazzle.com.
          </p>
          <div id="product-component-1654730892524"></div>
        </div>
        <div className="is-vcentered columns is-centered is-multiline">
          <div className="column is-12">
            <div className="mb-5">
              <a
                className="button is-primary is-fullwidth-mobile "
                href="https://www.zazzle.com/collections/flag_swag-119311331102562265"
              >
                Visit Zazzle Store
              </a>
            </div>
          </div>
        </div>
      </ContentSection>
    </div>
  )
}

const VendorCard = ({
  vendorName,
  vendorAddress,
  vendorAddressURL,
  vendorOfferings,
  storeLink,
  storeLinkText,
  vendorImgSrc,
  vendorImgAlt,
}) => {
  return (
    <div className="container">
      <div className="box mb-5">
        <div className="p-3 columns is-multiline is-vcentered">
          <div className="column is-12">
            <div className="is-vcentered columns is-multiline">
              <div className="column is-4 is-centered">
                <img
                  className="image is-fullwidth"
                  style={{ objectFit: "cover" }}
                  src={vendorImgSrc}
                  alt={vendorImgAlt}
                />
              </div>
              <div className="column is-8 content">
                <h2
                  className="is-size-3 has-text-weight-bold"
                  style={{
                    fontStyle: "italic",
                  }}
                >
                  {vendorName}
                </h2>
                <h4 className="title has-text-grey is-4">
                  <HyperLinkText link={vendorAddressURL} text={vendorAddress} />
                </h4>
                {vendorOfferings.length > 1 ? (
                  <ul className="subtitle has-text-grey mt-4">
                    {vendorOfferings.map((offering) => (
                      <li className=" ">{offering}</li>
                    ))}
                  </ul>
                ) : (
                  <p className="subtitle has-text-grey mt-4">
                    {vendorOfferings}
                  </p>
                )}
                <HyperLinkText link={storeLink} text={storeLinkText} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

ShopPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
}

const ShopPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <ShopPageTemplate title={frontmatter.title} />
    </Layout>
  )
}

ShopPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}
export default ShopPage

export const ShopPageQuery = graphql`
  query ShopPage {
    markdownRemark(frontmatter: { templateKey: { eq: "shop-page" } }) {
      html
      frontmatter {
        title
      }
    }
  }
`
